html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /** 
   * For some reason, overflow: hidden seems
   * to be ignored when the keyboard is shown.
   * Enabling these two because it works.
   * Mentioned here:
   * https://stackoverflow.com/questions/28411499/disable-scrolling-on-body
   */
  overflow-x: hidden;
  overflow-y: scroll;

  /** Set this to hidden to prevent main body
  bouncing when reaching the overflowed area **/
  /* overflow: hidden; */

  /** Disable text highlighting when user taps
  and hold the screen **/
  user-select: none;

  /**
   * Disable the horizontal swipe back and
   * vertical swipe to exit fullscreen mode on browsers.
   */
  overscroll-behavior: none;

  /**
   * Disables the pinch to zoom.
   */
  touch-action: none;

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
